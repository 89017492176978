import gql from 'graphql-tag'
import { PaymentMethod as PM } from '@/graphql/fragments'

export const PaymentMethods = gql`
  query paymentMethods($checkout: Boolean) {
    paymentMethods(checkout: $checkout) {
      ...${ PM }
    }
  } 
`

export const PaymentMethod = gql`
  query paymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      ...${ PM }
      preferences
    }
  } 
`
